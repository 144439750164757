import styled from "styled-components";

export const Container = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        margin: 0;
        margin-top: 19px;
        color: #8C96A0;
    }
`