import { Parent, Placeholder, Input } from "./styles";

import InputMask from 'react-input-mask'

function BaseInput({ width, type, name, placeholder, changeValue, error, errorMessage, mask, value }) {

    if (mask) {
        return (
            <Parent>
                <InputMask mask={mask} onChange={e => changeValue(e.target.value)} value={value} maskChar=" ">
                    {(inputProps) => <Input {...inputProps} width={width} className={error && 'error-input'} name={name} id={name} placeholder=" " required="required" />}
                </InputMask>
                <Placeholder className={error && 'error-label'} htmlFor={name}>{placeholder}</Placeholder>
                {error && <h4>{errorMessage}</h4>}
            </Parent>
        )
    } else {
        return (
            <Parent>
                <Input className={error && 'error-input'} type={type} name={name} id={name} placeholder=" " required="required" onChange={e => changeValue(e.target.value)} value={value} />
                <Placeholder className={error && 'error-label'} htmlFor={name}>{placeholder}</Placeholder>
                {error && <h4>{errorMessage}</h4>}
            </Parent>
        );

    }

}

export default BaseInput;