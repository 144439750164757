import { Container } from "./styles";

function CarrosselCard({ img, name, alt }) {
  return (
    <Container>
      <img src={img} loading="lazy" alt={alt} width="60px" height="60px" />
      <h1>{name}</h1>
    </Container>
  );
}

export default CarrosselCard;
