import React from "react";
import { Container, Content, Label, Title } from "./styles";

const Termos = ({ title, children }) => {
  return (
    <Container>
      <Label>Termos</Label>

      <Title>{title}</Title>

      <Content>{children}</Content>
    </Container>
  );
};

export default Termos;
