import { Container, BoxText, BoxEmpty } from "./styles";
import { Subtitle, Paragraph } from "../../components/Templates/texts";
import { SlideFromSide } from "../../components/Behavior";

function SectionInfos({ align, paragraph, title, position, img, alt }) {
  return (
    <Container align={align}>
      <SlideFromSide direction={position % 2 == 0 ? "right" : "left"}>
        <div>
          <img src={img} alt={alt} loading="lazy" />
        </div>
      </SlideFromSide>
      <BoxText>
        <Subtitle>{title}</Subtitle>
        <Paragraph>{paragraph}</Paragraph>
      </BoxText>
      <BoxEmpty />
    </Container>
  );
}

export default SectionInfos;
