import { Container, Submit } from "./styles";

function ButtonPattern({ children, anchor }) {
	return (
		<>
			{anchor ? (
				<Container>
					<a href={anchor} target="_blank" rel="noreferrer">
						{children}
					</a>
				</Container>
			) : (
				<Submit type="submit" value={children} />
			)}
		</>
	);
}

export default ButtonPattern;
