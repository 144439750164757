import styled from "styled-components";

export const HeaderContent = styled.div`
	background-color: #0f1e2d;
	display: flex;
	justify-content: space-between;
	padding: 15px 6vw;
	z-index: 2;
	top: 0;
	width: 100%;
	position: fixed;

	@media only screen and (max-width: 1200px) {
		flex-direction: column;
		padding: 20px;
		width: 100vw;
	}

	nav {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	nav ul {
		display: flex;
		list-style-type: none;

		@media only screen and (max-width: 1200px) {
			display: none;
		}

		a {
			font-weight: 500;
			font-size: 14px;
			margin-right: 30px;
			color: #fafafa;
			text-decoration: none;
			transition: color 0.25s ease-in-out;

			&:hover {
				color: #46dcaf;
			}
		}
	}
`;

export const LogoContainer = styled.div`
	display: flex;
	justify-content: space-between;
	z-index: 2;

	button {
		border: none;
		background: transparent;
		width: 31px;

		@media only screen and (min-width: 1200px) {
			display: none;
		}
	}

	a {
		display: flex;
		align-items: center;
	}
`;

export const ContainerEnd = styled.div`
	display: flex;
	align-items: center;

	span {
		font-size: 14px;
		font-weight: 500;
		margin-right: 37px;
		color: #fafafa;
		text-decoration: none;
		transition: color 0.25s ease-in-out;

		&:hover {
			color: #46dcaf;
		}
	}

	@media only screen and (max-width: 1200px) {
		display: none;
	}
`;

export const ContainerMenuMobile = styled.div`
	background-color: #0f1e2d;
	height: 101%;
	width: 100%;

	flex-direction: column;
	display: flex;
	gap: 2px;

	position: fixed;
	z-index: 2;
	right: 0;
	top: 0;

	overflow-y: none;

	button {
		background: transparent;
		border: none;

		position: absolute;
		right: 20px;
		top: 20px;

		&:hover {
			background: #339a82;
			transition: 0.5s;
		}
	}

	.top-mobile {
		margin: 20px;
		display: flex;
		justify-content: space-between;
	}

	ul {
		display: flex;
		flex-direction: column;

		margin-top: 40px;
		padding-left: 20px;
		list-style-type: none;

		li {
			margin-bottom: 20px;

			a {
				color: #fafafa;
				font-size: 14px;
				font-weight: 500;
				line-height: 17px;
				text-decoration: none;
			}
		}
	}
`;

export const ContainerFooter = styled.div`
	width: 100%;
	margin: 0;

	position: absolute;
	bottom: 50px;

	gap: 20px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: flex-end;

	span {
		font-size: 14px;
		font-weight: 500;
		line-height: 17px;

		color: #fafafa;
		text-decoration: none;
	}

	button {
		background: transparent;
		border: none;
		width: 80%;

		top: 0;
		right: 0;
		position: relative;

		a {
			padding: 15px 29vw;
		}
	}
`;
