import { useState } from "react";

import { ButtonPattern } from "..";
import ProductUrls from "@2ndmarket/components/src/helper/ProductUrls";

import {
	ContainerEnd,
	HeaderContent,
	LogoContainer,
	ContainerFooter,
	ContainerMenuMobile,
} from "./styles";

function Header() {
	const [menuMobileShow, setMenuMobileShow] = useState(false);

	return (
		<HeaderContent>
			<LogoContainer>
				<a href="">
					<img
						width="202px"
						height="24px"
						loading="lazy"
						src="/logo.png"
						className="logo"
						alt="Logo Tokenaim"
					/>
				</a>
				<button
					type="button"
					onClick={() => setMenuMobileShow(true)}
				>
					<img
						width="31"
						height="31"
						loading="lazy"
						alt="Ícone menu"
						src="/hamburguer-icon.svg"
					/>
				</button>
			</LogoContainer>
			<nav>
				<ul>
					<li>
						<a href="#tokenize">Tokenize</a>
					</li>
					<li>
						<a href="#como-funciona">Como Funciona</a>
					</li>
					<li>
						<a href="#beneficios">Benefícios</a>
					</li>
					<li>
						<a href="#custodia">Custódia</a>
					</li>
					<li>
						<a href="#marketplace">Marketplace</a>
					</li>
					<li>
						<a href={ProductUrls.BLOG}>
							Blog{" "}
							<img
								alt=""
								width={12}
								height={12}
								loading="lazy"
								src="/blog-icon.svg"
							/>
						</a>
					</li>
					<li>
						<a href="#contato">Contato</a>
					</li>
				</ul>
			</nav>
			<ContainerEnd>
				<span>
					<a
						href="https://auth.2ndmarket.com.br"
						rel="noreferrer"
						target="_blank"
					>
						Login
					</a>
				</span>
				<ButtonPattern anchor="https://auth.2ndmarket.com.br/cadastro">
					Cadastre-se
				</ButtonPattern>
			</ContainerEnd>

			{menuMobileShow && (
				<ContainerMenuMobile>
					<div className="top-mobile">
						<a href="">
							<img
								width="202px"
								height="24px"
								loading="lazy"
								src="/logo.png"
								className="logo"
								alt="Logo Tokenaim"
							/>
						</a>
						<button
							type="button"
							onClick={() => setMenuMobileShow(false)}
						>
							<img
								width="20"
								height="20"
								loading="lazy"
								src="/close-icon.svg"
								alt="Ícone para fechar menu"
							/>
						</button>
					</div>
					<ul>
						<li>
							<a
								onClick={() => setMenuMobileShow(false)}
								href="#tokenize"
							>
								Tokenize
							</a>
						</li>
						<li>
							<a
								onClick={() => setMenuMobileShow(false)}
								href="#como-funciona"
							>
								Como Funciona
							</a>
						</li>
						<li>
							<a
								onClick={() => setMenuMobileShow(false)}
								href="#beneficios"
							>
								Benefícios
							</a>
						</li>
						<li>
							<a
								onClick={() => setMenuMobileShow(false)}
								href="#custodia"
							>
								Custódia
							</a>
						</li>
						<li>
							<a
								onClick={() => setMenuMobileShow(false)}
								href="#marketplace"
							>
								Marketplace
							</a>
						</li>
						<li>
							<a href={ProductUrls.BLOG}>
								Blog{" "}
								<img
									alt=""
									width={12}
									height={12}
									loading="lazy"
									src="/blog-icon.svg"
								/>
							</a>
						</li>
						<li>
							<a
								onClick={() => setMenuMobileShow(false)}
								href="#contato"
							>
								Contato
							</a>
						</li>
					</ul>

					<ContainerFooter>
						<span>
							<a
								href="https://auth.2ndmarket.com.br"
								rel="noreferrer"
								target="_blank"
							>
								Login
							</a>
						</span>
						<ButtonPattern anchor="https://auth.2ndmarket.com.br/cadastro">
							Cadastre-se
						</ButtonPattern>
					</ContainerFooter>
				</ContainerMenuMobile>
			)}
		</HeaderContent>
	);
}

export default Header;
