import { Parent, Placeholder, Input } from "./styles";

import InputMask from "react-input-mask";

function PhoneInput({ type, name, placeholder, changeValue, mask, value }) {
  const maskPhone = (value) => {
    return value
      .replace(/\D/g, "")
      .replace(/^(\d{2})(\d)/g, "($1) $2")
      .replace(/(\d{5})(\d{4})(\d)/, "$1-$2");
  };

  if (mask) {
    return (
      <Parent>
        <InputMask
          mask={mask}
          onChange={(e) => changeValue(e.target.value)}
          value={value}
          maskChar=" "
        >
          {(inputProps) => (
            <Input
              {...inputProps}
              name={name}
              id={type}
              placeholder=" "
              required="required"
            />
          )}
        </InputMask>
        <Placeholder htmlFor={type}>{placeholder}</Placeholder>
      </Parent>
    );
  } else if (maskPhone) {
    return (
      <Parent>
        <Input
          type={type}
          name={name}
          id={type}
          placeholder=" "
          required="required"
          onChange={(e) => changeValue(maskPhone(e.target.value))}
          value={value}
        />
        <Placeholder htmlFor={type}>{placeholder}</Placeholder>
      </Parent>
    );
  } else {
    return (
      <Parent>
        <Input
          type={type}
          name={name}
          id={type}
          placeholder=" "
          required="required"
          onChange={(e) => changeValue(e.target.value)}
          value={value}
        />
        <Placeholder htmlFor={type}>{placeholder}</Placeholder>
      </Parent>
    );
  }
}

export default PhoneInput;
