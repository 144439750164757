import Link from "next/link";
import ProductUrls from "@2ndmarket/components/src/helper/ProductUrls";
import ProductCnpjs from "@2ndmarket/components/src/helper/ProductCnpjs";
import { TokenaimSocials } from "@2ndmarket/components/src/helper/ProductSocialNetworks";

import {
  FooterContent,
  SocialMediaContainer,
  TextsContainer,
  Box,
  BrandLink,
  FooterContainer,
} from "./styles";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <FooterContainer>
      <FooterContent>
        <SocialMediaContainer>
          <Link href="/">
            <a rel="noreferer">
              <img
                loading="lazy"
                src="/logo.png"
                alt="TokenAIM"
              />
            </a>
          </Link>
          <div>
            <Link href={TokenaimSocials.FACEBOOK}>
              <a target="_blank" rel="noreferer">
                <img loading="lazy" src="/facebook-footer.svg" alt="facebook" />
              </a>
            </Link>
            <Link href={TokenaimSocials.LINKEDIN}>
              <a target="_blank" rel="noreferer">
                <img loading="lazy" src="/linkedin-footer.svg" alt="linkedin" />
              </a>
            </Link>
            <Link href={TokenaimSocials.INSTAGRAM}>
              <a target="_blank" rel="noreferer">
                <img
                  loading="lazy"
                  src="/instagram-footer.svg"
                  alt="instagram"
                />
              </a>
            </Link>
          </div>
        </SocialMediaContainer>

        <Box>
          <p>Confira todo o nosso ecossistema:</p>
          <Link href={ProductUrls.BANKAI}>
            <a target="_blank" rel="noreferrer">
              Bank.ai
              <img
                loading="lazy"
                src="/blog-icon.svg"
                alt=""
                width={12}
                height={12}
              />
            </a>
          </Link>
          <Link href={ProductUrls.BRAEX}>
            <a target="_blank" rel="noreferrer">
              Bra.ex
              <img
                loading="lazy"
                src="/blog-icon.svg"
                alt=""
                width={12}
                height={12}
              />
            </a>
          </Link>
          <Link href={ProductUrls.CUSTON}>
            <a target="_blank" rel="noreferrer">
              Cust.on
              <img
                loading="lazy"
                src="/blog-icon.svg"
                alt=""
                width={12}
                height={12}
              />
            </a>
          </Link>
          <Link href={ProductUrls.TOKENAIM}>
            <a target="_blank" rel="noreferrer">
              Token.aim
              <img
                loading="lazy"
                src="/blog-icon.svg"
                alt=""
                width={12}
                height={12}
              />
            </a>
          </Link>
        </Box>
      </FooterContent>
      <TextsContainer>
        <p className="links">
          <Link href={ProductUrls.PRIVACY_POLICY}>
            <a target="_blank" rel="noreferrer">
              Política de Privacidade
            </a>
          </Link>
          <Link href={ProductUrls.TERMS_AND_CONDITIONS}>
            <a target="_blank" rel="noreferrer">
              Termos de Uso
            </a>
          </Link>
          <Link href={ProductUrls.SUPPORT}>
            <a target="_blank" rel="noreferrer">
              Preciso de Ajuda
            </a>
          </Link>
        </p>
        <p>
          © {currentYear}{" "}
          <BrandLink href={ProductUrls.SECOND_MARKET}>Second Market</BrandLink>{" "}
          - Todos direitos reservados
        </p>
        <p>CNPJ {ProductCnpjs.TOKENAIM}</p>

        <div className="logoFooter">
					<img
						src="../logo-2nd-footer.svg"
						width="143"
						height="21"
						alt="Logo 2ndMarket"
					/>
				</div>
      </TextsContainer>

      <div className="squares">
        <img src="../footer-squares.svg" alt="" width={287} height={187} />
      </div>
    </FooterContainer>
  );
}

export default Footer;
