import styled from "styled-components";

export const Cartola = styled.h2`
  color: #46dcaf;
  font-weight: ${(props) => (props.weight ? props.weight : "500")};
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 8px;
`;

export const Title = styled.h1`
  font-size: ${(props) => (props.size ? props.size : "42px")};
  font-weight: ${(props) => (props.weight ? props.weight : "bold")};
  line-height: ${(props) => (props.height ? props.height : "50px")};
  color: #fff;
  margin: 0;
`;

export const Subtitle = styled.h2`
  font-size: ${(props) => (props.size ? props.size : "16px")};
  font-weight: ${(props) => (props.weight ? props.weight : "500")};
  line-height: ${(props) => (props.height ? props.height : "22px")};
  color: #fafafa;
  margin-bottom: 6px;
`;

export const Paragraph = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: ${(props) => (props.color ? props.color : "#8c96a0")};
  margin: 0;
`;

export const Additional = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #fafafa;
  margin: 30px 0;
`;

export const Strong = styled.strong`
    font-weight: 600;
    color: ${ props => props.white ? 'white' : '#8C96A0' };

    margin-left: ${ props => props.marginLeft ? props.marginLeft : '0px' };
`;