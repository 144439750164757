import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  margin: 0 10vw;
  align-items: center;
  flex-direction: ${props => (props.align ? props.align : "row")};
  justify-content: space-between;
  margin-bottom: 30px;

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    margin: 0;

    img {
      margin-top: 25px;
    }
  }
`;

export const BoxText = styled.div`
  max-width: 30vw;

  h2 {
    margin-top: 0;
  }

  @media only screen and (max-width: 1200px) {
    max-width: 100%;
    margin-top: 25px;
  }
`;

export const BoxEmpty = styled.div`
  width: 230px;
  height: 230px;

  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;
