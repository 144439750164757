import styled from "styled-components";

export const Container = styled.button`
	border: none;
	background: none;
	padding: 0;
	display: flex;

	a,
	input {
		background-color: var(--color-tokenaim);
		border: none;
		border-radius: 2px;
		cursor: pointer;
		color: var(--color-grey-3);
		padding: 15px 2vw;
		font-family: Montserrat;
		font-weight: 500;
		font-size: 14px;
		transition: 0.25s;
		white-space: nowrap;

		@media only screen and (max-width: 1200px) {
			padding: 15px 7vw;
		}

		&:hover {
			background-color: var(--color-dark-green);
			color: #ffffff;
		}
	}
`;

export const Submit = styled.input.attrs((props) => ({
	type: "submit",
}))`
	display: block;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	font-family: Montserrat;
	color: var(--color-grey-3);

	padding: 15px 30px;
	text-align: center;
	cursor: pointer;
	border: 1px solid var(--color-tokenaim);
	-webkit-appearance: none;
	background: var(--color-tokenaim);
	border-radius: 2px;

	width: ${(props) =>
		props.minWidth ? props.minWidth : "initial"};
	margin-left: ${(props) =>
		props.marginLeft ? props.marginLeft : "initial"};

	transition: color 0.15s ease-in-out,
		background 0.15s ease-in-out;

	&:active {
		color: var(--color-grey-3);
		background: var(--color-tokenaim);
	}

	@media only screen and (max-width: 1200px) {
		width: 165px;
	}
`;
